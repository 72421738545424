.main-page-margin {
  padding: 0rem;

  .process-header {
    .text-field {
      overflow-y: hidden;
    }
    .text-field:hover {
      background-color: #fff;
      border-color: none;
    }

    .text-field:focus {
      border-color: none !important;
      box-shadow: none !important;
    }

    .process-btn {
      .main-title-option {
        border: none !important;
        background: none;
        color: black;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 2rem;
      }

      .main-title-option :hover {
        border: none !important;
        background-color: none !important;
        color: black;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 2rem;
      }

      .active-option {
        background: none !important;
        color: #8a00e5 !important;
        background: none !important;
        border: 0;
        color: black;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 2rem;
      }

      .not-active:hover {
        border: none;
        background: none !important;
        color: black !important;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 2rem;
      }

      .main-title-option :active {
        color: #8a00e5;
        background-color: none;
        --bs-btn-active-bg: none;
      }
    }
  }

  .topic-btn-div {
    padding-top: 3rem;
  }

  .process-table {
    .user-table {
      padding-top: 1rem;

      .table {
        display: block;
        empty-cells: show;
        --bs-table-striped-bg: none !important;

        thead {
          background: #ffff !important;
          color: black;
          position: relative;
          display: block;
          width: 100%;
          padding-bottom: 0;
        }

        tbody {
          display: block;
          position: relative;
          width: 100%;
          overflow-y: hidden;
          min-height: 38rem;
          text-align: center;

          tr:nth-child(even) {
            background-color: #f8f8f8;
          }
        }

        .more-user-btn {
          border: none;
          background: none;
          color: #8a00e5;
          font-size: small;
        }

        .more-user-btn:hover {
          text-decoration: underline;
        }

        tr {
          width: 100%;
          display: flex;
          border: none;
          height: 3.4rem;
        }

        th {
          display: block;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        th:first-child {
          text-align: left;
          padding-left: 1rem;
          width: 17%;
        }

        th:nth-child(2) {
          text-align: left;
          width: 20%;
          background-color: #ffff;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        th:nth-child(3) {
          text-align: left;
          width: 11%;
        }

        th:nth-child(4) {
          text-align: left;
          width: 16%;
        }

        th:nth-child(5) {
          text-align: left;
          width: 30%;
        }

        th:last-child {
          text-align: left;
          width: 6%;
        }

        td {
          vertical-align: left;
          border: none !important;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        td:first-child {
          padding-left: 1rem;
          text-align: left;
          width: 17%;
        }

        td:nth-child(2) {
          text-align: left;
          width: 20%;
        }

        td:nth-child(3) {
          width: 11%;
          text-align: left;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        td:nth-child(4) {
          text-align: left;
          width: 16%;
        }

        td:nth-child(5) {
          text-align: left;
          width: 30%;
        }

        td:last-child {
          text-align: left;
          width: 6%;
        }
      }
    }
  }

  .topic-table {
    .user-table {
      padding-top: 1rem;

      .table {
        display: block;
        empty-cells: show;
        --bs-table-striped-bg: none !important;

        thead {
          background: #ffff !important;
          color: black;
          position: relative;
          display: block;
          width: 100%;
          padding-bottom: 0;
        }

        tbody {
          display: block;
          position: relative;
          width: 100%;
          overflow-y: hidden;
          min-height: 38rem;
          text-align: center;

          tr:nth-child(even) {
            background-color: #f8f8f8;
          }
        }

        .more-user-btn {
          border: none;
          background: none;
          color: #8a00e5;
          font-size: small;
        }

        .more-user-btn:hover {
          text-decoration: underline;
        }

        tr {
          width: 100%;
          display: flex;
          border: none;
          height: 3.4rem;
        }

        th {
          display: block;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        th:first-child {
          text-align: left;
          padding-left: 1rem;
          width: 25%;
        }

        th:nth-child(2) {
          text-align: left;
          width: 25%;
          background-color: #ffff;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        th:nth-child(3) {
          text-align: left;
          width: 20%;
        }

        th:nth-child(4) {
          text-align: left;
          width: 20%;
        }

        th:last-child {
          text-align: left;
          width: 10%;
        }

        td {
          vertical-align: left;
          border: none !important;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        td:first-child {
          padding-left: 1rem;
          text-align: left;
          width: 25%;
        }

        td:nth-child(2) {
          text-align: left;
          width: 25%;
        }

        td:nth-child(3) {
          width: 20%;
          text-align: left;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        td:nth-child(4) {
          text-align: left;
          width: 20%;
        }

        td:last-child {
          text-align: left;
          width: 10%;
        }
      }
    }
  }

  .add-and-edit-process {
    .input-width {
      width: 50.5%;
    }
  }

  .add-new-process {
    .input-width {
      width: 51%;
    }
  }
}

.process-user-page {
  background-color: #f6f4ff;
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  .grid-container {
    display: grid;
    padding-left: "0.7rem";
    padding-right: "0.7rem";
    min-height: "47rem";
  }

  .grid-item {
    width: 100%;
    min-width: 18%;
    max-width: 18%;
    margin-right: 1%;
    margin-left: 1%;
  }

  .process-content {
    margin: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: white;
    border-radius: 5px;
  }

  .custom-dropdown-main {
    .dropdown-title {
      font-size: 21px;
      font-weight: 600;
    }

    .dropdown-btn {
      cursor: pointer;
    }

    #dropdown-basic :active {
      background-color: none !important;
    }

    .btn:first-child:active {
      background-color: none !important;
    }

    .btn-primary {
      --bs-btn-active-bg: none !important;
    }

    .custom-dropdown {
      --bs-dropdown-min-width: 33rem;
      padding: 1rem;
    }
  }

  .dropdown-select .react-dropdown-select-dropdown {
    width: 100% !important;
  }

  .main-process-container {
    max-width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .process-cards {
    padding: 0.5rem;
    border-style: dashed;
    border-width: 2.3px;
    border-radius: 8px;
    margin-bottom: 30px;
    background-color: white;
    border-color: #b3b3b3;
    cursor: pointer;

    .process-title-content {
      max-width: 70%;
    }

    .process-name {
      color: black !important;
    }

    .process-name:hover {
      text-decoration: none !important;
    }
    .process-title {
      font-size: 20px;
      font-weight: 600;
      max-height: 2em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      position: relative;

      .tooltip-text {
        visibility: hidden;
        width: 200px;
        background-color: black;
        color: white;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -100px;
        opacity: 0;
        transition: opacity 0.3s;
      }

      &:hover .tooltip-text {
        visibility: visible;
        opacity: 1;
      }
    }

    .org-container {
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      height: 1.5rem;
    }
    .tooltip-1 {
      #tooltip-title > .tooltip-inner {
        background-color: #cc2020 !important;
        color: #000;
        border: 1px solid #062e56;
      }
    }

    .org-title {
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 0.2rem;
    }

    .comma-separator {
      margin: 0 0.3rem;
      white-space: nowrap;
    }

    .highlighted {
      .process-cards {
        background-color: black;
      }
    }

    .not-highlighted {
      background-color: white;
    }
    .org-title {
      color: #8a00e5;
      font-size: 15px;
      font-weight: 300;

      .org-title-link {
        color: #8a00e5 !important;
      }
    }

    .process-desc {
      font-size: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      min-height: 3.1rem;
      max-height: 3.5rem;
    }
    .members-count {
      margin-top: auto;
      font-size: 13px;
      color: #8a00e5;
      margin-top: 3px;
    }
    .members-count:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .process-cards:hover {
    border-color: #8a00e5;
  }

  .highlighted {
    .process-cards {
      background-color: black;
    }
  }

  .process-title {
    position: relative;
    cursor: pointer;

    .tooltip-text {
      visibility: hidden;
      width: 200px;
      background-color: black;
      color: white;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 50%;
      margin-left: -100px;
      opacity: 0;
      transition: opacity 0.3s;
    }
    .tooltip-inner {
      min-width: 600px;
      max-width: 600px;
      word-wrap: break-word;
      background-color: #f5f5f5;
      color: #333;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 4px;
    }

    .tooltip-arrow::before {
      border-top-color: #f5f5f5;
    }

    &:hover .tooltip-text {
      visibility: visible;
      opacity: 1;
    }
  }

  .process-desc {
    position: relative;
    cursor: pointer;
    width: max-content;

    // .tooltip-inner {
    //   min-width: 600px;
    //   max-width: 600px;
    //   word-wrap: break-word;
    //   background-color: #f5f5f5;
    //   color: #333;
    //   padding: 10px;
    //   border: 1px solid #ddd;
    //   border-radius: 4px;
    // }

    // .tooltip-arrow::before {
    //   border-top-color: #f5f5f5;
    // }
  }
}

.process-btn {
  color: black !important;
  cursor: pointer !important;
  &:hover {
    text-decoration: none !important;
    text-underline-offset: none !important;
  }
}

.preview-page {
  .custom-font-size {
    font-size: 17px;
    font-weight: 400;
  }

  .custom-desc-color {
    color: #5f5f5f;
    font-size: 17px;
  }

  .custom-link {
    color: #8a00e5;
  }
}

.process-desc-height {
  height: 228px !important;
}

.layers-flow {
  height: 40rem;
  padding-bottom: 1rem;

  .flow-canvas {
    height: 100% !important;
    width: 100% !important;

    background-color: #f8f8f8;
  }
}

.layoutflow {
  flex-grow: 1;
  position: relative;
  height: 100%;
  margin-top: 6px;
  margin-bottom: 6px;
  border: 1px solid #dee2e6;
  height: 40rem;

  .flow-canvas {
    height: 100% !important;
    width: 100% !important;

    background-color: #f8f8f8;
  }
}

.layoutflow .controls {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
  font-size: 12px;
}

.layoutflow .controls button:first-child {
  margin-right: 10px;
}

.react-flow__attribution {
  display: none;
}

.react-flow__handle {
  width: 2px !important;
  height: 2px !important;
}

.react-flow__node-default,
.react-flow__node-group,
.react-flow__node-input,
.react-flow__node-output {
  border: 1px solid rgba(100, 30, 140, 1) !important;
  padding: 0 !important;
  border-radius: 4px !important;
  text-align: unset !important;

  .node-up {
    background: #e6e6e6;
  }
  .node-down {
    border-radius: 3px;
    background: #ffffff;
  }

  .layerText {
    padding: 10px 8px 0 6px;
    font-size: 10px;
    color: white;
  }
  .nameText {
    padding: 8px 8px 9px 6px;
    font-size: 13px;
    line-height: 12px;
    color: white;
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .descText {
    font-size: 10px;
    padding: 8px 8px 25px 6px;
    line-height: 11px;
    color: #000;
    letter-spacing: 0.2px;
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .processDescText {
    height: 60px;
    padding: 3px 5px 3px 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
}

#tooltip-title > .tooltip-inner {
  background-color: black !important;
  color: white;
  max-width: 600px;
  border: 1px solid #062e56;
}

.org-list-item {
  font-size: 16px;
  font-weight: 500;
}

.org-list-item:hover {
  font-size: 16px;
  font-weight: 500;
  pointer-events: cursor;
  color: #8a00e5;
}

.list-group-item {
  color: none !important;
  text-decoration: none;
  background-color: none !important;
  border: none !important;
}
