.main-page-margin {
  padding: 0;

  .main-manage {
    padding: 2rem;
    .search-div {
      input {
        border: 1.5px solid #969696;
        border-radius: 2px;
      }

      input:focus {
        outline: none;
        border: 1.5px solid #8a00e5;
        background: none;
      }

      input :hover {
        background: none;
        border: 2px solid #969696;
      }

      input::-webkit-search-cancel-button {
        -webkit-appearance: none;
        // content: url("../../assets/images/search_close.svg");
        cursor: pointer;
      }

      .search-bar {
        width: 15rem;
        height: 2.5rem;
        background: url("../../assets/images/search.png");
        background-repeat: no-repeat;
        position: relative;
        height: 40px;
        padding-left: 5px;
        background-size: 23px;
        background-position: right;
        background-position-x: 13rem;
        outline-offset: initial;
        -webkit-appearance: auto;
      }

      .search-bar:hover {
        background: none;
      }

      .user-btn {
        float: right;
        color: #8a00e5;
        font-weight: bold;
        background: none;
        border: 2px solid #8a00e5;
        border-radius: 20px;
        height: 2.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .user-btn:hover {
        background: white;
        color: #641e8c;
        border-color: #641e8c;
      }
    }
    .community-table {
      .user-community-table {
        // padding-top: 1rem;

        .table {
          display: block;
          empty-cells: show;
          --bs-table-striped-bg: none !important;

          thead {
            background: #ffff !important;
            color: black;
            position: relative;
            display: block;
            width: 100%;
            padding-bottom: 0;
          }

          tbody {
            display: block;
            position: relative;
            width: 100%;
            overflow-y: hidden;
            min-height: 42rem;
            text-align: center;

            tr:nth-child(even) {
              background-color: #f8f8f8;
            }
          }

          .more-user-btn {
            border: none;
            background: none;
            color: #8a00e5;
            font-size: small;
          }

          .more-user-btn:hover {
            text-decoration: underline;
          }

          tr {
            width: 100%;
            display: flex;
            border: none;
            height: 3.4rem;
          }

          th {
            display: block;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          th:first-child {
            text-align: left;
            padding-left: 1rem;
            width: 15%;
          }

          th:nth-child(2) {
            text-align: left;
            width: 15%;
            background-color: #ffff;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          th:nth-child(3) {
            text-align: left;
            width: 20%;
          }

          th:nth-child(4) {
            text-align: left;
            width: 20%;
          }

          th:nth-child(5) {
            text-align: left;
            width: 20%;
          }

          th:last-child {
            text-align: left;
            width: 10%;
          }

          td {
            vertical-align: left;
            border: none !important;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          td:first-child {
            padding-left: 1rem;
            text-align: left;
            width: 15%;
          }

          td:nth-child(2) {
            text-align: left;
            width: 15%;
          }

          td:nth-child(3) {
            width: 20%;
            text-align: left;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          td:nth-child(4) {
            text-align: left;
            width: 20%;
          }

          td:nth-child(5) {
            text-align: left;
            width: 20%;
          }

          td:last-child {
            text-align: left;
            width: 10%;
          }
        }
      }

      .add-community {
        min-height: none;
      }
    }
  }

  .search-div {
    input {
      border: 1.5px solid #969696;
      border-radius: 2px;
    }

    input:focus {
      outline: none;
      border: 1.5px solid #8a00e5;
      background: none;
      // background-repeat: no-repeat;
      // background-position: right;
      // background-position-x: 13rem
    }

    input :hover {
      background: none;
      border: 2px solid #969696;
    }

    input::-webkit-search-cancel-button {
      -webkit-appearance: none;
      // content: url("../../assets/images/search_close.svg");
      cursor: pointer;
    }

    .search-bar {
      width: 15rem;
      height: 2.5rem;
      background: url("../../assets/images/search.png");
      background-repeat: no-repeat;
      position: relative;
      height: 40px;
      padding-left: 5px;
      background-size: 23px;
      background-position: right;
      background-position-x: 13rem;
      outline-offset: initial;
      -webkit-appearance: auto;
    }

    .search-bar:hover {
      background: none;
    }

    .user-btn {
      float: right;
      color: #8a00e5;
      font-weight: bold;
      background: none;
      border: 2px solid #8a00e5;
      border-radius: 20px;
      height: 2.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .user-btn:hover {
      border-color: #641e8c;
      background: white;
      color: #641e8c;
    }
  }

  .text-field-yammer-link {
    margin-right: 12px;
    width: 90.5%;
  }
  .community-page {
    overflow-x: hidden;

    .community-form {
      overflow-x: hidden;
      overflow-y: hidden;

      // .form-check-option {
      //   .form-check-input:checked {
      //     background-color: #8a00e5;
      //     border-color: #8a00e5;
      //   }

      //   .form-check-input:focus {
      //     outline: 0;
      //     box-shadow: none !important;
      //   }
      // }

      .text-field-disable {
        overflow-y: hidden;
        max-height: 190px;
      }

      .text-field-disable:hover {
        cursor: not-allowed;
        border-color: #8a00e5;
      }

      .text-field-disable:focus {
        border-color: #8a00e5 !important;
        box-shadow: none !important;
      }

      .employee-add-btn {
        float: right;
      }

      .employee-add-btn:hover {
        color: white;
      }

      // .text-field-yammer-link {
      //   margin-right: 12px;
      //   width: 86.5%;
      // }

      .text-background {
        background-color: #8a00e5;
        color: white;
      }
    }
  }
}

.community-employee-modal {
  .custom-checkbox {
    position: relative;
    width: 16px;
    height: 16px;
    accent-color: #8900e4;
    margin-top: 3px;
  }
  .modal-dialog {
    min-width: 1200px;
  }

  .employee-users {
    min-height: 505px;
  }
}

.search-div {
  input {
    border: 1.5px solid #969696;
    border-radius: 2px;
  }

  .rbt-close:nth-child(1) {
    // visibility: hidden !important;
  }

  .rbt-close .rbt-close-content {
    display: none;
  }

  .rbt-aux .rbt-close {
    margin-top: -0.25rem;
    pointer-events: auto;
    font-size: 11px;
  }

  .dropdown-menu.show {
    display: block;
    --bs-dropdown-link-active-bg: #8a00e5;
  }

  input:focus {
    outline: none;
    border: 1.5px solid #8a00e5;
    background: none;
    box-shadow: none;
  }

  input :hover {
    background: none;
    border: 2px solid #969696;
    color: red;
  }

  input::-webkit-search-cancel-button {
    -webkit-appearance: none;
    content: url("../../assets/images/search_close.svg");
    cursor: pointer;
  }

  .search-bar {
    height: 2.5rem;
    background: url("../../assets/images/search.png");
    background-repeat: no-repeat;
    position: relative;
    height: 40px;
    padding-left: 18px;
    background-size: 23px;
    background-position: right;
    background-position-x: 43rem;
    outline-offset: initial;
    -webkit-appearance: auto;
  }

  .search-div:focus {
    outline: none;
    border: 1.5px solid #8a00e5;
    background: none;
    box-shadow: none;
  }

  .search-bar:hover {
    background: none;
  }

  .org-search {
    margin-left: 2px;
  }
}

.selected-members {
  color: #8900e4;
  font-weight: 600;
}

.data-loader {
  height: 500px;
}

.users-row {
  .custom-checkbox-align {
    position: relative;
    top: 15px;
    right: 5px;
    width: 16px;
    height: 16px;
    accent-color: #8900e4;
  }

  .custom-card-user {
    width: 100%;
    background: #f8f8f8;
    min-height: 5.4em;
    max-height: 5.4em;
  }

  .custom-name {
    line-height: 1.2em;
    font-size: 17px;
    font-weight: 400;
    word-spacing: 0.2rem;
    height: 48px;
    word-break: "break-word";
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .custom-email {
    font-size: 11px;
  }

  .overflow-ellipses {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 180px;
  }

  .highlight-card {
    border: #8900e4;
    background-color: #8a00e5;
    width: 100%;
    background: #f8f8f8;
    min-height: 5em;
    max-height: 5em;
  }
}

.tooltip-style {
  background-color: #8a00e5;
}

.custom-width-search-1 {
  width: calc(66% - 16px);
}

.custom-width-search-2 {
  width: calc(34% - 16px);
}

.add-btn {
  --bs-btn-hover-border-color: #8a00e5;
  --bs-btn-hover-bg: #8a00e5;
  --bs-btn-active-bg: white;
  border-radius: 30px !important;
  height: 2.5rem !important;
  margin-top: 15px !important;
  text-align: -webkit-center !important;
  padding: 2px;
  width: 137px;
}

.user-btn {
  float: right;
  color: #8a00e5;
  font-weight: bold;
  background: none;
  border: 2px solid #8a00e5;
  border-radius: 20px;
  height: 2.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-btn:hover {
  background: #8a00e5;
  color: white;
}

.employee-users {
  .container {
    .users-row {
      .user-names {
        display: flex;
        min-width: 200px;
        background: #f8f8f8;
        height: 3rem;
      }
    }
  }

  .page-item {
    .active {
      z-index: 3 !important;
      color: white !important;
      background-color: var(--bs-pagination-active-bg) !important;
      border-color: var(--bs-pagination-active-border-color) !important;
      --bs-pagination-focus-box-shadow: none !important;
      --bs-pagination-hover-color: none;
    }
  }

  .page-link:hover {
    color: none;
  }
}

.custom-linehight {
  line-height: 1.2em;
}
