.community-user {
  .title-bar {
    box-shadow: 0px 1px 4px 0px #00000026;
    padding: 0.5 rem;

    .page-title {
      color: #8a00e5;
      font-size: 2rem;
      font-weight: bold;
    }

    .close-button {
      color: #8a00e5;
      background: none;
      margin-left: 2rem;
      border: 0px;
      font-size: 20px;
      font-weight: bold;
    }

    .close-button:hover {
      color: #641e8c;
      text-underline-offset: none;
    }
  }

  .main-page-margin {
    padding: 1.2rem;

    .com-list-comp {
      width: 95%;
      // border: 1px solid black;
      border-radius: 15px;
      background-color: #f7eaff;
      cursor: pointer;

      .com-list-comp-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 98%;
      }

      &:hover {
        border: 1px solid #8a00e5;
      }
    }

    #news-carousel {
      background-color: #8a00e5;
    }

    .com-btn {
      color: #8a00e5;
      background: none;
      // margin-left: 2rem;
      border: 1.5px solid #8a00e5;
      font-size: 20px;
      font-weight: bold;
      border-radius: 3rem;
    }

    .act-com-btn {
      color: white;
      background-color: #8a00e5;
    }

    .top-com-list {
      height: 735px;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: white;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(217, 217, 217, 1);
      border-radius: 10px;
    }

    div:hover::-webkit-scrollbar-thumb {
      background: gray;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: gray !important;
    }

    .community-user-page {
      background-color: #8a00e5;
      .main-community-title {
        font-size: 26px;
      }

      .community-main-link {
        color: #8a00e5 !important;
        text-decoration: underline;
      }

      .desc-ellipses {
        display: "inline-block";
        max-width: "40%";
        overflow: "hidden";
        text-overflow: "ellipsis";
        white-space: "nowrap";
      }
    }

    .community-desc {
      color: #5f5f5f;
    }

    .teams-link {
      color: #8a00e5;
    }

    .select-option {
      width: 15rem;
      height: 2.5rem;
      position: relative;
      padding-left: 5px;
      outline-offset: initial;
      -webkit-appearance: auto;
    }
  }
}

.employee-show-modal {
  .modal-dialog {
    min-width: 1200px;
  }

  .employee-div {
    min-height: 505px;
  }

  .employee-name {
    min-width: 380px;
  }
  .custom-name {
    min-width: 230px;
  }

  .custom-name-font {
    font-weight: 700;
  }
  .add-btn {
    --bs-btn-hover-border-color: #8a00e5;
    --bs-btn-hover-bg: #8a00e5;
    --bs-btn-active-bg: white;
    border-radius: 30px !important;
    height: 2.5rem !important;
    margin-top: 15px !important;
    text-align: -webkit-center !important;
    padding: 2px;
    width: 137px;
  }

  .data-load {
    height: 500px;
  }
}

.main-community-page {
  background-color: #f6f4ff;
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  .grid-container {
    display: grid;
  }

  .grid-item {
    width: 100%;
    min-width: 18%;
    max-width: 18%;
    margin-right: 1%;
    margin-left: 1%;
  }

  .community-content {
    margin: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: white;
    border-radius: 5px;
  }

  .custom-dropdown-main {
    .dropdown-title {
      font-size: 21px;
      font-weight: 600;
    }

    .dropdown-btn {
      cursor: pointer;
    }

    #dropdown-basic :active {
      background-color: none !important;
    }

    .btn:first-child:active {
      background-color: none !important;
    }

    .btn-primary {
      --bs-btn-active-bg: none !important;
    }

    .custom-dropdown {
      --bs-dropdown-min-width: 33rem;
      padding: 1rem;
    }
  }

  .dropdown-select .react-dropdown-select-dropdown {
    width: 100% !important;
  }

  .main-community-container {
    max-width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .community-cards {
    padding: 0.5rem;
    border-style: dashed;
    border-width: 2.3px;
    border-radius: 8px;
    margin-bottom: 30px;
    background-color: white;
    border-color: #b3b3b3;
    cursor: pointer;

    .community-title-content {
      max-width: 70%;
    }

    .community-name {
      color: black !important;
    }

    .community-name:hover {
      text-decoration: none !important;

      .community-type-container {
        min-height: 4.3rem;
      }
    }
    .community-title {
      font-size: 20px;
      font-weight: 600;
      max-height: 2em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .community-type {
      background-color: #f7eaff;
      width: 25%;
      border-radius: 10px;
      text-align: center;
      font-size: 12px;
      color: #8a00e5;
    }
    .community-desc {
      font-size: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 60%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      min-height: 3.1rem;
      max-height: 3.5rem;
    }
    .members-count {
      margin-top: auto;
      font-size: 13px;
      color: #8a00e5;
      margin-top: 3px;
    }
    .members-count:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .community-cards:hover {
    border-color: #8a00e5;
  }
}

.community-user-page {
  .main-community-title {
    font-size: 26px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    gap: 0.8rem;
  }

  .grid-item {
    max-width: 100%;
  }
}
