#train-carousel .carousel-inner {
  position: relative;
  overflow: hidden;
}

#train-carousel .carousel-indicators {
  display: none;
}

#train-carousel .nav-link {
  color: black;
}

#train-carousel {
  .carousel-control-next {
    color: #7e24dc;
    font-size: 2.5rem;
    width: 2rem;
    right: -4%;
  }

  .carousel-control-prev {
    color: #7e24dc;
    font-size: 2.5rem;
    width: 2rem;
    left: -4%;
  }
}

.training-wrapper {
  align-items: flex-start;

  .card {
    cursor: pointer;
    width: 100%;
    height: 19.5rem;
    --bs-card-border-radius: none;
    --bs-card-inner-border-radius: none;
    transition: 0.6s;
    .img-hover-zoom {
      z-index: 1;
      overflow: hidden;
    }

    .img-hover-zoom .card-img-bottom {
      width: 100%;
      height: 14.5rem;
      object-fit: cover;
      transition: transform 0.5s ease;
    }

    .card-title {
      font-size: 1.1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
      width: 96%;
      --s: 0.1em;
      --c: #641e8c;
      color: black;
      padding-bottom: var(--s);
      background: linear-gradient(90deg, var(--c) 50%, #000 0)
          calc(100% - var(--_p, 0%)) / 200% 100%,
        linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s) no-repeat;
      -webkit-background-clip: text, padding-box;
      background-clip: text, padding-box;
      transition: 0.5s;
    }

    .card-subtitle {
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 96%;
    }
  }

  .card:hover {
    --_p: 100%;
    --bs-card-border-color: black;
  }
}

.sub-training-wrapper {
  align-items: flex-start;

  .card {
    width: 14.5rem;
    height: 13rem;
    --bs-card-border-radius: none;
    --bs-card-inner-border-radius: none;
    transition: 0.6s;
    .img-hover-zoom {
      z-index: 1;
      overflow: hidden;
    }

    .img-hover-zoom .card-img-bottom {
      width: 100%;
      height: 8.5rem;
      object-fit: cover;
      transition: transform 0.5s ease;
    }

    .card-title {
      font-size: 1.1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
      width: 96%;
      --s: 0.1em;
      --c: #641e8c;
      color: black;
      padding-bottom: var(--s);
      background: linear-gradient(90deg, var(--c) 50%, #000 0)
          calc(100% - var(--_p, 0%)) / 200% 100%,
        linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s) no-repeat;
      -webkit-background-clip: text, padding-box;
      background-clip: text, padding-box;
      transition: 0.5s;
    }

    .card-subtitle {
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 96%;
    }
  }

  .card:hover {
    --_p: 100%;
    --bs-card-border-color: black;
  }
}
