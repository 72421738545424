.banner-carousel {
  box-shadow: 5px 10px 8px #888888;
}

.banner-img {
  width: 100%;
}

.banner-carousel {
  padding-bottom: 20px;
  box-shadow: inset 0 0 0 0 #641e8c;

  .carousel-indicators [data-bs-target] {
    background-color: #8a00e5;
  }

  .carousel-indicators {
    display: flex !important;
  }
}

.button-1 {
  background-color: #641e8c;

  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 30px;
  letter-spacing: 0.2px;
  border-color: #641e8c;
}

.button-1:hover {
  background-color: transparent;
  color: #641e8c;
  border-color: #641e8c;
}

.banner-carousel a {
  text-decoration: none;
  color: white;
}

.banner-carousel a:hover {
  color: #641e8c;
}

.banner-carousel .carousel-caption {
  text-align: left;
  left: 9% !important;
}

.fade-in-text {
  font-size: 5vw;
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
