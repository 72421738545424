#main-navbar {
  position: sticky;
  top: 0;
  z-index: 100;
  .container {
    max-width: 95%;
  }
  .dropdown {
    .dropdown-item {
      --bs-dropdown-link-active-color: white;
      --bs-dropdown-link-active-bg: #641e8c;
      .dropdown-items {
        color: black;
        &:active {
          color: white;
        }
      }
    }
  }
}

#main-navbar .nav-link {
  margin-left: 30px;
}

.dropdown-toggle::after {
  display: none;
}

#basic-nav-dropdown {
  margin-left: 5px !important;
}

.color-nav {
  background-color: white;
  border-bottom: 2px solid #8a00e5;
}

#basic-navbar-nav {
  .nav-link {
    font-weight: 500;
    font-size: 20px;
    --s: 0.2em;
    --c: #8a00e5;
    color: black;
    padding-bottom: var(--s);
    background: linear-gradient(90deg, var(--c) 50%, #000 0)
        calc(100% - var(--_p, 0%)) / 200% 100%,
      linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s) no-repeat;
    -webkit-background-clip: text, padding-box;
    background-clip: text, padding-box;
    transition: 0.3s;
  }
  .nav-link.active {
    color: #8a00e5;
    box-shadow: 0px -4px 0px 0px #8a00e5 inset;
  }
  .sub-link.active {
    color: black;
    box-shadow: none !important;
  }
}

#demo-nav .nav-link:hover {
  --_p: 100%;
}

[data-initials]:before {
  background: transparent;

  color: #8a00e5;
  opacity: 1;
  content: attr(data-initials);
  display: inline-block;
  font-size: 1rem;
  border: 3px solid #8a00e5;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 0.5em;
  width: 35px;
  height: 35px;
  line-height: 29px;
  text-align: center;
}

.navbar-nav .dropdown-menu {
  border-radius: 0px;
  --bs-dropdown-link-active-bg: none;
  --bs-dropdown-link-active-color: none;
  border: 1.5px solid black;
}

#session-loader {
  width: 2rem;
  height: 2rem;
  border: 3px solid #f3f3f3;
  border-top: 4px solid #8a00e5;
  border-radius: 100%;
  margin: auto;
  /* visibility: hidden; */
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
