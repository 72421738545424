.react-datepicker {
  font-size: 1em;
}

.react-datepicker-popper {
  z-index: 5;
}

.react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name {
  width: 3.1em;
  line-height: 1.5em;
  margin: 0.166em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  font-weight: bold;
}
.react-datepicker__day {
  width: 3.1em;
  height: 4.3em;
  line-height: 1.3em;
  font-weight: bold;
}
.react-datepicker__day:hover {
  background-color: #e6e6e6;
}

.react-datepicker__day--today {
  border: 2px solid #8a00e5 !important;
  border-radius: 5px;
  color: black;
  background-color: transparent !important;
}

.react-datepicker__current-month {
  font-size: 1em;
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: #e6e6e6;
}

.download-ultimo-btn {
  margin-left: 6.5em;
  margin-bottom: 0.5em;
  font-size: 1em;
  font-weight: bold;
  width: 13.4em;
  height: 2.5em;
  background-color: transparent;
  border: 2px solid #8a00e5;
  border-radius: 2em;
  color: #8a00e5;
}

.download-ultimo-btn:hover {
  border-color: #641e8c;
  color: #641e8c;
}

.react-datepicker__navigation--next {
  display: block;
  background: url("../../assets/images/arrowRight.svg");
  background-repeat: no-repeat;
  background-size: 1.4em 1.4em !important;
  right: 30px;
  top: 10px;
}

.react-datepicker__navigation--previous {
  display: block;
  background: url("../../assets/images/arrowLeft.svg");
  background-repeat: no-repeat;
  background-size: 1.4em 1.4em !important;
  left: 30px;
  top: 10px;
}

.react-datepicker__children-container {
  width: 100%;
}
