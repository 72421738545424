.chatbot-icon {
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 7rem;
}

.rcw-full-screen {
  .rcw-conversation-container {
    position: fixed;
    right: 0;
  }
}

.rcw-widget-container {
  .rcw-conversation-container {
    min-width: 40vw;
    max-width: 40vw;
    .rcw-header {
      color: black;
      background-color: #f3e0ff;
      padding: 15px 15px 15px;
      text-align: left;
      border-bottom: 1px solid #8a00e5;
      .rcw-close-button {
        position: fixed;
        top: 4%;
        right: 3%;
        display: block;
        height: 2rem;
        width: 2rem;
        background-color: #8a00e5;
        border: 0;
        border-radius: 50%;
        .rcw-close {
          height: 1.5rem;
          width: 1rem;
        }
      }
      .rcw-title {
        // font-size: 20px;
        padding: 0;
      }
    }
    .rcw-sender {
      background-color: white;
      .rcw-new-message {
        border: 1px solid #d8d8d8;
        width: calc(100% - 30px);

        &:hover {
          border-color: #8a00e5;
        }

        &:active {
          border-color: #8a00e5;
        }

        &:focus-within {
          border-color: #8a00e5 !important;
        }

        .rcw-input {
          margin-left: 5px;
        }
      }

      .rcw-picker-btn {
        display: none;
      }
      .rcw-send {
        border: 1px solid #d8d8d8;
        margin-left: 10px;
        border-radius: 7px;
        height: 2.6rem;
        width: 2.2rem;
        &:hover {
          border-color: #8a00e5;
        }
      }
    }
    .rcw-messages-container {
      .rcw-response {
        .rcw-message-text {
          width: 100%;
          max-width: 32rem;
          background-color: #f3e0ff;
          border-radius: 30px;
          pre {
            border-radius: 10px !important;
          }
        }
      }
      .rcw-client {
        .rcw-message-text {
          width: 100%;
          max-width: 32rem;
          background-color: #f2f2f2;
          border-radius: 30px;
        }
      }

      .rcw-avatar-client {
        border: 1px solid black;
      }
    }

    .loader {
      .loader-container {
        background-color: #f3e0ff;
        min-height: 1rem !important;
        max-height: 1rem !important;
        max-width: 4rem;
        border-radius: 30px;
      }
    }
  }
  .rcw-launcher {
    background-color: #8a00e5;
    height: 4.1rem;
    width: 4.2rem;
    .rcw-open-launcher {
      height: 3.5rem;
      width: 3.5rem;
      border-radius: 50%;
    }
    .rcw-close-launcher {
      width: 25px;
    }
  }
}
