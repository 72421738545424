.generic-card {
  .header-content {
    font-size: 20px;
    font-weight: 600;
    max-height: 2em;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    color: black;
  }
  .links-section {
    .link-item {
      text-decoration: none;
      cursor: pointer;
      color: #8a00e5;
      font-size: 15px;
      font-weight: 300;
    }
  }
  .description-section {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    font-size: 15px;
    max-height: 3.5rem;
    min-height: 3.1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }
}
