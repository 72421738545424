.main-manage {
  padding: 2rem;

  .back-icon {
    .navigate-back {
      font-weight: bold;
      padding-left: 0.5%;
    }
  }

  .card-container {
    padding-bottom: 1rem;
    padding-top: 1rem;

    .default-user-col {
      .card {
        border: 1.5px solid #8a00e5;
      }

      .card:hover {
        margin: 0px;
      }
    }

    .card {
      background: #f7eaff;
      word-wrap: break-word;
      transition: 0.2s;

      .card-body {
        padding: 7px;
        height: 120px !important;

        .card-type {
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          -webkit-line-clamp: 1;
          line-height: 1.2;
          font-weight: bold;
          text-align: left;
          width: 90%;
          overflow-wrap: initial;
        }

        .card-data {
          color: #8a00e5;
          font-weight: 1000;
          font-size: 35px;
          overflow-wrap: initial;
        }
      }
    }
  }

  .card:hover {
    border-color: #8a00e5;
    border-width: 1.5px;
    margin: -2px;
  }

  .non-admin {
    height: 100vh;
    margin-top: 13%;
    text-align: center;
  }

  .main-title {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 2rem;
  }

  .sub-bar {
    flex-direction: row;
    justify-content: left;

    .search-div {
      input {
        border: 1.5px solid #969696;
        border-radius: 2px;
      }

      input:focus {
        outline: none;
        border: 1.5px solid #8a00e5;
        background: none;
      }

      input :hover {
        background: none;
        border: 2px solid #969696;
      }

      input::-webkit-search-cancel-button {
        -webkit-appearance: none;
        content: url("../../assets/images/search_close.svg");
        cursor: pointer;
      }

      .search-bar {
        width: 15rem;
        height: 2.5rem;
        background: url("../../assets/images/search.png");
        background-repeat: no-repeat;
        position: relative;
        height: 40px;
        padding-left: 5px;
        background-size: 23px;
        background-position: right;
        background-position-x: 13rem;
        outline-offset: initial;
        -webkit-appearance: auto;
      }

      .search-bar:hover {
        background: none;
      }

      .user-btn {
        float: right;
        color: #8a00e5;
        font-weight: bold;
        background: none;
        border: 2px solid #8a00e5;
        border-radius: 20px;
        height: 2.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .user-btn:hover {
        background: white;
        color: #641e8c;
        border-color: #641e8c;
      }
    }
  }

  .user-table {
    padding-top: 1rem;

    .main-table {
      .text-overflow {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .table {
      display: block;
      empty-cells: show;
      --bs-table-striped-bg: none !important;
      min-height: 38rem;

      thead {
        background: #ffff !important;
        color: black;
        position: relative;
        display: block;
        width: 100%;
      }

      tbody {
        display: block;
        position: relative;
        width: 100%;
        text-align: center;

        tr:nth-child(even) {
          background-color: #f8f8f8;
        }
      }

      .is-admin-btn {
        cursor: pointer;

        .remove-admin {
          color: #8a00e5;
          text-decoration: underline;
          font-weight: bold;
        }
      }

      tr {
        width: 100%;
        display: flex;
        border: none;
        height: 3rem;
      }

      th {
        display: block;
        text-align: center;
      }

      th:first-child {
        text-align: left;
        padding-left: 1rem;
        width: 35%;
      }

      th:nth-child(2) {
        text-align: left;
        width: 35%;
        background-color: #ffff;
      }

      th:nth-child(3) {
        width: 15%;
        text-align: justify;
      }

      th:last-child {
        width: 15%;
        text-align: justify;
      }

      td {
        vertical-align: middle;
        border: none !important;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      td:first-child {
        padding-left: 1rem;
        text-align: left;
        width: 35%;
      }

      td:nth-child(2) {
        text-align: left;
        width: 35%;
      }

      td:nth-child(3) {
        width: 15%;
        text-align: justify;
      }

      td:last-child {
        width: 15%;
      }
    }
  }
}
